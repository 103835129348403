<template>
	<v-sheet v-if="accountId != null && accountData != null && user">
		<v-container>
			<v-row no-gutters>
				<v-col cols="12">
					<v-toolbar flat>
						<v-toolbar-title>{{ accountId ? '编辑用户信息': '添加新用户'}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							small
							@click="$router.go(-1)"
							elevation="0">
							返回上页
						</v-btn>
					</v-toolbar>
				</v-col>
			</v-row>
		</v-container>
		<v-form ref="form" v-model="valid" lazy-validation @submit.prevent="onFormSubmit">
			<v-container v-if="accountData" class="py-0">
				<v-row>
					<v-col xl="2" lg="3" md="12" sm="12" xs="12">
							<!--- Default avatar --->
							<div class="d-flex flex-column align-center">
								<template v-if="profile_picture">
									<v-img
										:alt="`${accountData.name}的用户头像`"
										contain
										max-height="180"
										:src="profile_picture"
										transition="scale-transition"
										width="180"
									>
									<template v-slot:placeholder>
											<v-row
											class="fill-height ma-0"
											align="center"
											justify="center"
											>
											<v-progress-circular
												indeterminate
												color="grey lighten-5"
											></v-progress-circular>
											</v-row>
										</template>
									</v-img>
								</template>
								<template v-else>
									<v-img
										alt="Empire CBS default avatar"
										contain
										src="@/assets/worker_avatar.png"
										transition="scale-transition"
										width="180"
									/>
								</template>
								<v-btn v-if="accountId"  class="my-4" color="info" text @click="initUploader('profile_picture')"><v-icon left>mdi-camera</v-icon> 更换图片</v-btn>
							</div>
						
							<v-divider class="my-4"></v-divider>
							<div class="d-flex pa-4 flex-column flex-end" style="gap: 12px">
								<v-switch inset color="red"  v-model="accountData.archived" class="mt-0 mb-6 ml-4">
									<template v-slot:label>
										<v-chip :color="accountData.archived ? 'error' :'success'">
											{{accountStatus}}
										</v-chip>
									</template>
								</v-switch>
							<template v-if="user.role === 'admin' && !accountData.archived">
								<v-row class="px-3" no-gutters>
									<v-col cols="12" sm="12">
										<v-checkbox
											class="mt-0"
											v-model="accountData.allow_login"
											label="用户是否可以登陆"
										></v-checkbox>
									</v-col>
								</v-row>
								<v-row v-if="accountId && accountData.allow_login" no-gutters class="px-3">
									<v-col cols="12" sm="12">
										<v-checkbox
											class="mt-0"
											v-model="updatePassword"
											label="修改用户密码"
										></v-checkbox>
										<v-text-field
											v-if="updatePassword"
											dense
											v-model="password"
											:append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
											:rules="[rules.required, rules.min]"
											:type="showpassword ? 'text' : 'password'"
											label="输入新密码"
											hint="密码至少包含8个字符"
											class="mt-4"
											outlined
											@click:append="showpassword = !showpassword"
											counter
										></v-text-field>
									</v-col>
								</v-row>
								<v-row v-else class="px-3" no-gutters>
									<v-col cols="12" sm="12" v-if="accountData.allow_login">
										<v-text-field
											v-model="password"
											dense
											:append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
											:rules="[rules.required, rules.min]"
											:type="showpassword ? 'text' : 'password'"
											label="创建您的密码"
											hint="密码至少包含8个字符"
											class="mt-8"
											outlined
											@click:append="showpassword = !showpassword"
											counter
										></v-text-field>
									</v-col>
								</v-row>
							</template>
							</div>

							<user-team-tab v-if="accountId" :userId="accountId" :team="accountData.workTeams" class="mt-6" />
					</v-col>
					<v-divider vertical></v-divider>
					<v-col xl="10" lg="9" md="12" sm="12" xs="12">
						<!--- Main Area --->
						<v-sheet>
							<!--- 基本信息 --->
							<v-toolbar dense flat>
								<v-toolbar-title class="subtitle-2 font-weight-bold"><v-icon left>mdi-account-circle</v-icon>个人信息</v-toolbar-title>
							</v-toolbar>
							<v-divider class="mb-6"></v-divider>
							<v-row class="px-3" no-gutters>
								<v-col cols="12" sm="4" >
									<v-text-field
										v-model="accountData.name"
										dense
										outlined
										:rules="nameRules"
										label="用户姓名"
										class="mx-2"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="4">
									<template v-if="user && user.role === 'admin'">
										<v-select
											v-model="accountData.role"
											dense
											:items="roles"
											item-text="text"
											item-value="value"
											outlined
											label="用户角色"
											class="mx-2"
											:rules="[rules.required]"
										></v-select>
									</template>
									<template v-else>
										<v-text-field
											:value="getUserRoleText(accountData.role)"
											dense
											outlined
											disabled
											class="mx-2"
											label="用户角色"
											/>
									</template>
								</v-col>
								<v-col cols="12" sm="4">
									<v-text-field
										:value="computedDateFormattedDatefns(accountData.date_of_birth)"
										clearable
										dense
										:rules="[rules.required]"
										label="出生日期"
										outlined
										class="mx-2"
										readonly						
										required
										@click="dobMenu = true"
										@click:clear="accountData.date_of_birth = null"
									></v-text-field>
									<v-dialog v-model="dobMenu" max-width="290">
										<v-date-picker
											v-model="accountData.date_of_birth"
											:first-day-of-week="0"
											show-current
											locale="zh-cn"
											class="pb-6"
											@change="dobMenu = false"
										></v-date-picker>
									</v-dialog>
								</v-col>
								<v-col cols="12" sm="4">
									<v-text-field
										v-model="emailInput"
										:rules="[rules.emailRules]"
										dense
										outlined
										label="用户邮箱"
										class="mx-2"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="4">
									<v-text-field
										dense
										v-model="accountData.phone"
										:rules="[rules.required]"
										outlined
										class="mx-2"
										label="联系电话"
										required
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="4">
									<v-text-field
										dense
										v-model="accountData.meta.address"
										:rules="[rules.required]"
										outlined
										class="mx-2"
										label="家庭地址"
										required
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="12">
									<v-text-field
										v-model="accountData.meta.notes"
										dense
										outlined
										class="mx-2"
										label="用户备注">
									</v-text-field>
								</v-col>				
							</v-row>

							<v-row class="px-3">
								<v-col cols="12" xl="8" lg="8" sm="12">
									<!--- 工作信息 --->
									<v-toolbar dense flat>
										<v-toolbar-title class="subtitle-2 font-weight-bold"><v-icon left>mdi-account-hard-hat</v-icon>工作信息</v-toolbar-title>
									</v-toolbar>
									<v-divider class="mb-6"></v-divider>
									<v-row no-gutters>
										<v-col cols="12" xl="6" lg="6" sm="12">
											<!--- Start Date --->
											<v-text-field
												:value="computedDateFormattedDatefns(accountData.meta.start_date)"
												clearable
												dense
												label="开始日期"
												outlined
												readonly
												class="mx-2"
												@click="startDateMenu = true"
												@click:clear="accountData.meta.start_date = null"
											></v-text-field>
											<v-dialog v-model="startDateMenu" max-width="290">
												<v-date-picker
													v-model="accountData.meta.start_date"
													:first-day-of-week="0"
													show-current
													locale="zh-cn"
													class="pb-6"
													@change="startDateMenu = false"
												></v-date-picker>
											</v-dialog>
											<!--- TFN -->
											<v-text-field
												v-model="accountData.meta.tfn"
												dense
												outlined
												class="mx-2"
												label="TFN(税号)"
											></v-text-field>
											<!--- CBUS --->
											<v-text-field
												v-model="accountData.meta.cbus"
												dense
												outlined
												class="mx-2"
												label="Cbus"
											></v-text-field>
											<!--- Coinvest -->
											<v-text-field
												v-model="accountData.meta.coinvest"
												dense
												outlined
												class="mx-2"
												label="Coinvest"
											></v-text-field>
										</v-col>
										<v-col cols="12" xl="6" lg="6" sm="12">
											<!--- End Date --->
											<v-text-field
												:value="computedDateFormattedDatefns(accountData.meta.end_date)"
												clearable
												dense
												label="结束日期"
												class="mx-2"
												outlined
												readonly
												@click="endDateMenu = true"
												@click:clear="accountData.meta.end_date = null"
											></v-text-field>
											<v-dialog v-model="endDateMenu" max-width="290">
												<v-date-picker
													v-model="accountData.meta.end_date"
													:first-day-of-week="0"
													show-current
													locale="zh-cn"
													class="pb-6"
													@change="endDateMenu = false"
												></v-date-picker>
											</v-dialog>
											<!--- Work Safe --->
											<v-text-field
												v-model="accountData.meta.work_safe"
												dense
												outlined
												class="mx-2"
												label="Work Safe"
											></v-text-field>
											<!--- Incolink --->
											<v-text-field
												v-model="accountData.meta.incolink"
												dense
												outlined
												class="mx-2"														
												label="Incolink"
											></v-text-field>
											<!--- Cfmeu -->
											<v-text-field
												v-model="accountData.meta.cfmenu"
												dense
												outlined
												class="mx-2"	
												label="Cfmeu"
											></v-text-field>
										</v-col>
									</v-row>
								</v-col>

								<!--- 工作证件 --->
								<v-col cols="12" xl="4" lg="4" sm="12" v-if="accountId">
									<v-toolbar dense flat>
										<v-toolbar-title class="subtitle-2 font-weight-bold"><v-icon left>mdi-file-upload-outline</v-icon>工作证件</v-toolbar-title>
									</v-toolbar>
									<v-divider class="mb-6"></v-divider>
									<div class="d-flex align-center flex-column justify-center">
										<v-btn elevation="0" color="primary" class="mb-2" @click="initUploader('compliance_cards')"><v-icon left >mdi-file-upload</v-icon>上传证件照片</v-btn>
										<template v-if="compliance_cards">
											<v-img
												:alt="`${accountData.name}的工作证件`"
												contain
												max-height="200"
												:src="compliance_cards"
												transition="scale-transition"
												width="240"
												@click="enlargeComplianceCard"
											>
											<template v-slot:placeholder>
													<v-row
													class="fill-height ma-0"
													align="center"
													justify="center"
													>
													<v-progress-circular
														indeterminate
														color="grey lighten-5"
													></v-progress-circular>
													</v-row>
												</template>
											</v-img>
										</template>
										<template v-else>
											<div class="file-uploader-wrapper">
												<v-icon :size="120">mdi-cloud-upload</v-icon>
											</div>
										</template>
									</div>
								</v-col>

								<v-col v-if="accountId" cols="12" xl="8" lg="8" sm="12">
									<user-payment-tab :userId="accountId" @on-error="popToast($event, 'error')" @on-success="popToast($event, 'success')" class="mr-2" />
								</v-col>
								<v-col cols="12" xl="4" lg="4" sm="12">
									<!--- 支付信息 --->
									<v-toolbar dense flat>
										<v-toolbar-title class="subtitle-2 font-weight-bold"><v-icon left>mdi-cash-multiple</v-icon>支付信息</v-toolbar-title>
									</v-toolbar>
									<v-divider class="mb-8"></v-divider>
										<v-select
											item-title="text"
											item-value="value"
											:items="paymentMethod"
											v-model="accountData.meta.payment_method"
											dense
											class="mx-2"
											outlined
											label="工资支付方式"
										>
									</v-select>
									<template v-if=" accountData.meta.payment_method === 'bank_transfer'">
										<v-text-field
											v-model="accountData.meta.payee_bank_name"
											dense
											outlined
											class="mx-2"
											label="Bank Name"
										></v-text-field>
										<v-text-field
											v-model="accountData.meta.payee_account_name"
											dense
											outlined
											class="mx-2"
											label="Account Name"
										></v-text-field>
										<v-text-field
											v-model="accountData.meta.payee_bsb"
											dense
											outlined
											:rules="[rules.bsbMax]"
											class="mx-2"
											label="BSB"
										></v-text-field>
										<v-text-field
											v-model="accountData.meta.payee_account_number"
											dense
											outlined
											class="mx-2"
											label="Account Number"
										></v-text-field>
								</template>
								<!--- ABN Optional -->
								<v-text-field
										v-model="accountData.meta.abn"
										dense
										outlined
										class="mx-2"
										label="ABN (Optional)"
									></v-text-field>
								</v-col>

							</v-row>

						<!--- Main Area End --->
						<template >
						</template>
						<v-divider class="mt-6"></v-divider>
						<div class="d-flex justify-space-between mt-4 mb-6">
							<v-btn
								v-if="!accountId"
								color="error"
								text
								@click="reset"
								>
								重新填写
							</v-btn>
							<v-btn
								:disabled="!valid"
								color="success"
								elevation="0"
								@click="validate"
								type="submit"
							>
								{{submitText}}
							</v-btn>
						</div>
						</v-sheet>
					</v-col>
				</v-row>
			</v-container>
		</v-form>
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- loading overlay ---->
		<v-overlay :value="overlay">
			<v-progress-circular
			:size="70"
			:width="7"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-overlay>
		<!---- Image Uploader --->
		<v-dialog v-model="dlgUploader" max-width="680" eager>
			<uploader
				:type="uploadType"
				zone="user"
				:id="accountId"
				@on-success="updateProfilePicture($event)"
				@on-cancel="dlgUploader = false">
				<slot>{{fileUploaderTitle}}</slot>
			</uploader>
		</v-dialog>
		<!--- Image Lightbox --->
		<v-dialog v-model="dlgLightbox" max-width="1280">
			<v-card>
				<v-img
					:alt="`${accountData.name}的工作证件-original`"
					:src="compliance_cards"
					contain
					@click="enlargeComplianceCard"
				/>
			</v-card>
		</v-dialog>
	</v-sheet>
</template>

<script>

import { mapState } from 'vuex';
import UserServices from '../../services/User';

import {  parse, format } from 'date-fns';
import UserPaymentTab from '../../components/User/UserPaymentTab.vue';
import UserTeamTab from '../../components/User/UserTeamTab.vue';
import UserSiteLocationTab from '../../components/User/UserSiteLocationTab.vue';
import Uploader from '../../components/Uploader/Uploader.vue';

export default {
	name: 'viewAccount',
	components: {
		UserPaymentTab,
		UserTeamTab,
		UserSiteLocationTab,
		Uploader
	},
	created(){
		this.accountId = parseInt(this.$route.params.id);
		if(this.accountId){
			this.getAccountDetail();
		}
		if(this.$route.params.role) {
			this.accountData.role = this.$route.params.role;
		}
	},
	data: () => ({
		valid: true,
		paymentMethod: [
			{ text: 'Bank Transfer', value: 'bank_transfer' },
			{ text: 'Cash', value: 'cash'}
		],
		uploadType: 'profile_picture',
		accountId: null,
		accountData: {
			name: '',
			phone: '',
			role: '',
			date_of_birth: '',
			archived: '',
			allow_login: false,
			meta:{
				address: null,
				tfn: null,
				work_safe: null,
				cbus: null,
				incolink: null,
				coinvest: null,
				cfmenu: null,
				start_date: null,
				end_date: null,
				notes: null,
				payment_method: null,
				abn: '',
				payee_bank_name: '',
				payee_bsb: '',
				payee_account_name: '',
				payee_account_number: '',
			}
		},
		emailInput: null,
		currentEmail: null,
		compliance_cards: null,
		profile_picture: null,
		loading: false,
		dobMenu: false,
		startDateMenu: false,
		endDateMenu: false,
		nameRules: [
			v => !!v || '请输入名称',
			v => (v && v.length >= 2) || '姓名至少2个字',
		],
		rules: {
			required: value => !!value || '这是必填内容.',
			min: v => v.length >= 8 || '密码至少8个字符长度',
			bsbMax: v=> v && v.length <= 6 || 'BSB不能超过6位数',
			emailMatch: () => (`您输入的登陆邮箱或密码错误`),
			emailRules: v => {
				if (v != null && v.length > 0 ) {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(v) || '请输入正确的电子邮件格式'
				} return true
			},
		},
		roles: [
			{ text: 'Administrator （系统管理员）', value: 'admin'},
			{ text: 'Site Manager (工地经理)', value: 'site-manager'},
			{ text: 'Team Leader (组长)', value: 'team-leader'},
			{ text: 'Worker（工人）', value: 'worker'},
			{ text: 'Contractor（合同工）', value: 'contractor'},
		],
		password: '',
		updatePassword: false,
		showpassword: false,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		profilePictureChanged: false,
		complianceFormChanged: false,
		dlgUploader: false,
		overlay: false,
		dlgLightbox: false
	}),
	watch: {

	},
	computed: {
		...mapState('user', ['user'],),
		accountStatus(){
			return this.accountData.archived ? '未激活' : '激活';
		},
		submitText(){
			return this.accountId ? '更新用户' : '添加用户';
		},
		fileUploaderTitle(){
			return this.uploadType === 'profile_picture' ? '添加用户头像':'添加用户证件';
		}
	},
	methods: {
		validate () {
			this.$refs.form.validate();
		},
		formatDate (date) {
				if (!date) return null
				if (date.includes('-')){
					const [year, month, day] = date.split('-')
				return `${day}/${month}/${year}`
				}
				if (date.includes('/')){
					return date
				}
		},
		computedDateFormattedDatefns (date) {
			return date ? this.formatDate(date) : ''
		},
		async getAccountDetail(){
			try {
				let response = await UserServices.getUserById(this.accountId);
				if(response.data.statusCode === 200){
					const user = response.data.data;

					this.accountData.allow_login = user.allow_login;
					this.accountData.archived = user.archived;
					this.accountData.date_of_birth = user.date_of_birth ? format(parse(user.date_of_birth, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'): null;

					this.currentEmail = user.email;
					this.emailInput = this.currentEmail;
					this.accountId = user.id;

					this.accountData.name = user.name;
					this.accountData.phone = user.phone;
					this.accountData.role = user.role;
					//map all the meta field.
					this.accountData.meta.abn = user.meta.abn;
					this.accountData.meta.address = user.meta.address;
					this.accountData.meta.cbus = user.meta.cbus;
					this.accountData.meta.cfmenu = user.meta.cfmenu;
					this.accountData.meta.coinvest = user.meta.coinvest;
					this.accountData.meta.cbus = user.meta.cbus;
					this.accountData.meta.end_date = user.meta.end_date ? format(parse(user.meta.end_date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd') : null;
					this.accountData.meta.incolink = user.meta.incolink;
					this.accountData.meta.notes = user.meta.notes;
					this.accountData.meta.payee_account_name = user.meta.payee_account_name;
					this.accountData.meta.payee_account_number = user.meta.payee_account_number;
					this.accountData.meta.payee_bank_name = user.meta.payee_bank_name;
					this.accountData.meta.payee_bsb = user.meta.payee_bsb;
					this.accountData.meta.payment_method = user.meta.payment_method ? user.meta.payment_method : null;
					this.accountData.meta.start_date = user.meta.start_date ? format(parse(user.meta.start_date, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd') : null;
					this.accountData.meta.tfn = user.meta.tfn;
					this.accountData.meta.work_safe = user.meta.work_safe;
					
					this.accountData.workTeams = user.workTeams;
					this.profile_picture =  user.meta.profile_picture && user.meta.profile_picture.length > 0 ? user.meta.profile_picture : null ;
					this.compliance_cards = user.meta.compliance_cards && user.meta.compliance_cards.length > 0 ? user.meta.compliance_cards : null ;
				}
			} catch(error) {	
				this.popToast(`获取用户信息错误${error.data.message}`, 'error');
			}
		},
		getUserRoleText(roleName){
			const matchRole =  this.roles.filter((role)=> role.value === roleName);
			return matchRole[0].text;
		},
		onUserUpdated(){
			this.dialogEditUser = false;
			this.loading = true;
		},
		reset () {
			this.$refs.form.reset()
		},
		onFormSubmit(){
			this.overlay = true;
			//create paylod body here

			const body = this.accountData
				if(this.accountData.date_of_birth){
					body.date_of_birth = format(parse(this.accountData.date_of_birth, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
				}
				if(this.accountData.meta.start_date){
					body.meta.start_date = format(parse(this.accountData.meta.start_date, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
				}
				if(this.accountData.meta.end_date){
					body.meta.end_date = format(parse(this.accountData.meta.end_date, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
				}

				//check email change
				if(this.currentEmail != this.emailInput){
					body.email = this.emailInput;
				}
				if(this.accountData.allow_login){
					if( this.updatePassword ) {
						body.password = this.password;
					}
				}

			if(this.accountId){
				if(this.profilePictureChanged){
					body.meta.profile_picture = this.profile_picture;
				}
				if(this.complianceFormChanged){
					body.meta.compliance_cards = this.compliance_cards;
				}
				this.updateUser(body);
			} else {
				this.createUser(body);
			}
		},
		async updateUser(body){
			try {
				let response = await UserServices.updateUser(this.accountId, body);

				if(response.data.statusCode === 200){
					this.popToast(`用户${response.data.message}`, 'success');
					setTimeout(()=>{		
						location.reload();
					}, 1000)
				}
			} catch(error){
				this.popToast(`错误信息：${error.data.message}`, 'error');
			}
		},
		async createUser(body){
			try {
				let response = await UserServices.createUser(body);

				if(response.data.statusCode === 200){
						this.popToast(`用户${response.data.message}`, 'success');
						setTimeout(()=>{
							this.$router.push({ name: 'team', query: { tab: 1 } })
							location.reload();
						}, 1000)
				}
			} catch(error){
				this.popToast(`错误信息：${error.data.message}`, 'error');
			}
		},
		updateProfilePicture(key){
			if (this.uploadType === 'profile_picture') {
				this.profile_picture = key;
				this.profilePictureChanged = true;
			} else {
				this.compliance_cards = key;
				this.complianceFormChanged = true;
			}
			this.dlgUploader = false;
			this.popToast('文件上传成功', 'success');
			this.onFormSubmit();
		},
		popToast(message, type){
			this.snackbar.message = message;
			this.snackbar.color = type;
			this.overlay = false;
			this.snackbar.model = true;
		},
		initUploader(type){
			this.uploadType = type;
			this.dlgUploader = true
		},
		enlargeComplianceCard(){
			this.dlgLightbox = true;
		}
	}
}
</script>
<style scoped>
	.file-uploader-wrapper{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 215px;
		height: 165px;
		margin-top: 12px;
		background-color: #e5e5e5;
	}
</style>