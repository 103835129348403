<template>
	<v-card>
		<v-card-title><slot></slot></v-card-title>
		<v-divider></v-divider>
		<v-sheet>
			<template v-if="loading">
				<div class="d-flex justify-center flex-column align-center" style="height:420px;" >
					<v-progress-circular
					:size="70"
					:width="7"
					color="primary"
					indeterminate
					></v-progress-circular>
					<div class="body-1 text-center mt-6">上传图片中，请稍后...</div>
				</div>
			</template>
			<template v-else>
				<v-tabs v-model="tab" grow>
					<v-tab href="#tab-camera"><v-icon left>mdi-webcam</v-icon>使用摄像头拍照</v-tab>
					<v-tab href="#tab-upload"><v-icon left>mdi-cloud-upload</v-icon>从本地上传照片</v-tab>
				</v-tabs>
				<v-divider></v-divider>
				<v-tabs-items v-model="tab">
					<v-tab-item value="tab-camera">
					<!--- 摄像头抓取 --->
						<v-sheet class="px-8 mt-8 mb-4" min-height="480">
							<div class="d-flex justify-center mb-2">
								<template v-if="photoData">
									<v-btn color="info" elevation="0" @click="photoData = null"><v-icon left>mdi-camera-retake-outline</v-icon>重新拍摄</v-btn>
								</template>
								<template v-else>
									<v-btn color="success" elevation="0" @click="capturePhoto"><v-icon left>mdi-camera-plus-outline</v-icon>拍摄照片</v-btn>
								</template>
								<v-btn color="primary" elevation="0" @click="switchCamera" v-if="videoDevices.length > 1">Switch Camera</v-btn>
							</div>
							<div class="d-flex justify-center flex-column">
								<v-alert v-if="cameraErrorMessage" 
									color="red"
									dense
									type="error"
									>{{ cameraErrorMessage }}</v-alert>
								<video v-show="!photoData" ref="video" width="600" height="450" autoplay></video>
								<canvas ref="canvas" width="600" height="450" style="display:none;"></canvas>
								<img :src="photoData" alt="Captured Photo" width="600"  v-if="photoData">
							</div>
						</v-sheet>
					</v-tab-item>
					<!--- 上传文件 --->
					<v-tab-item value="tab-upload" >
							<v-sheet class="px-8 mt-8" min-height="480">
								<v-alert v-if="fileErrorMessage" 
									color="red"
									dense
									type="error"
									class="mb-6"
									>{{ fileErrorMessage }}</v-alert>
								<v-file-input
									v-model="file"
									color="primary"
									class="mb-8"
									counter
									label="选择你的文件"
									dense
									placeholder="选择你的文件"
									prepend-icon="mdi-cloud-upload"
									outlined
									:show-size="1000"
								>
							</v-file-input>
						</v-sheet>
					</v-tab-item>
				</v-tabs-items>
			</template>

		</v-sheet>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="error" text @click="$emit('on-cancel')">取消</v-btn>
			<v-btn color="success" elevation="0" :disabled="isUploadDisabled" @click="getPreSignnedURL">上传</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>

import axios from 'axios';
import UtilServices from '../../services/Utils';

export default {
	name: 'uploader',
	data: () => ({
		tab: 0,
		file: null,
		photoData: null,
		cameraErrorMessage: '',
		fileErrorMessage: '',
        videoDevices: [],
        currentDeviceIndex: 0,
		loading: false,
		key: null,
		presignedUrl: null,
	}),
	props:{
		type: {
			type: String,
			required: true
		},
		zone: {
			type: String,
			required: true
		},
		id: {
			type: String | Number,
			required: true
		}
	},
	mounted() {
        this.getCameras();
    },
	computed: {
		isUploadDisabled(){
			if(this.tab === 'tab-camera') {
				return this.photoData ? false : true;
			} else {
				return this.file ? false : true;
			}
		}
	},
    methods: {
		getCameras() {
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    this.videoDevices = devices.filter(device => device.kind === 'videoinput');
                    if (this.videoDevices.length > 0) {
                        this.startCamera(this.videoDevices[this.currentDeviceIndex].deviceId);
                    } else {
                        this.cameraErrorMessage = '无法找到设备摄像头.';
                    }
                })
                .catch((err) => {
                    this.handleError(err);
                });
        },
        startCamera(deviceId) {
            const constraints = {
                video: {
                    deviceId: deviceId ? { exact: deviceId } : undefined
                }
            };
            navigator.mediaDevices.getUserMedia(constraints)
                .then((stream) => {
                    this.$refs.video.srcObject = stream;
                })
                .catch((err) => {
                    this.handleError(err);
                });
        },
		switchCamera() {
            this.currentDeviceIndex = (this.currentDeviceIndex + 1) % this.videoDevices.length;
            this.startCamera(this.videoDevices[this.currentDeviceIndex].deviceId);
        },
        handleError(err) {
            if (err.name === 'NotAllowedError') {
                this.cameraErrorMessage = '获取摄像头权限错误. 请确认您在设备上打开了摄像头权限之后再尝试';
            } else if (err.name === 'NotFoundError') {
                this.cameraErrorMessage = '无法找到摄像头';
            } else if (err.name === 'NotReadableError') {
                this.cameraErrorMessage = '摄像头正在被其它应用程序使用';
            } else if (err.name === 'OverconstrainedError') {
                this.cameraErrorMessage = 'The specified constraints cannot be satisfied by any available camera.';
            } else {
                this.cameraErrorMessage = 'An unknown error occurred while accessing the camera.';
            }
        },
        capturePhoto() {
            const video = this.$refs.video;
            const canvas = this.$refs.canvas;
            const context = canvas.getContext('2d');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            this.photoData = canvas.toDataURL('image/png');
        },
		uploadPhoto(presignedUrl){
			if(!presignedUrl){
				this.loading = false;
				return;
			} 

			if (this.tab === 'tab-camera') {
				//camera capture
				const buffer = this.base64ToBuffer(this.photoData);
				this.uploadToS3(buffer, presignedUrl, 'image/jpeg');


			} else {
				//device upload
				if(!this.file){
					this.fileErrorMessage = '请选择一个文件。'
					return;
				}

				const validTypes = ['image/png', 'image/jpeg', 'image/gif'];
				if (!validTypes.includes(this.file.type)) {
					this.fileErrorMessage = '请选择正确的文件格式。我们自支持 PNG, JPEG 和GIF格式的图片文件';
					return;
				}

				const reader = new FileReader();
				reader.onload = (event)=> {
					const arrayBuffer = event.target.result;
					const bytes = new Uint8Array(arrayBuffer);
					this.uploadToS3(bytes, presignedUrl, this.file.type);
				};

				reader.onerror = (event)=> {
					this.fileErrorMessage = '无法读取文件。';
				};

				reader.readAsArrayBuffer(this.file);
			}
			
		},
		base64ToBuffer(base64) {
			const base64String = base64.split(',')[1];
			const binaryString = atob(base64String);
			const len = binaryString.length;
			const bytes = new Uint8Array(len);
			for (let i = 0; i < len; i++) {
				bytes[i] = binaryString.charCodeAt(i);
			}
			return bytes;
		},
		async getPreSignnedURL(){
			try {
				this.loading = true;

				const payload = {
				    type: this.type,
    				file_name: `${this.zone}_${this.type}_${this.id}.jpg`
				}
				const response = await UtilServices.getS3AssignedUrl(payload);
				
				if(response.data.statusCode === 200){
					this.key = response.data.data.key;
					this.uploadPhoto(response.data.data.presignedUrl)
				} else {
					this.loading = false;
				}

			} catch(error){
				const message = `We are unable to retreive the presigned url: ${error}`
				this.$emit('on-error', message)
			}
		},
		async uploadToS3(buffer, url, fileType) {
			const requestOptions = {
				headers: { 'Content-Type': fileType },
				body: JSON.stringify(buffer)
			};
			try {
				const response = await axios.put(url, buffer, {
						headers: {
							'Content-Type': fileType }
						})
				if(response.status === 200){
					this.loading = false;
					this.$emit('on-success', this.key)
				}
			} catch(error){
				const message = `上传图片失败，请检查网络然后重新尝试: ${error}`
				this.$emit('on-error', message)
			}
		}
    }
}
</script>
