<template>
	<div>
		Site location of {{ userId }}
	</div>
</template>
<script>
export default {
	name: 'UserSiteLocationTab',
	props: {
		userId: {
			type: Number,
			required: false,
			default: null
		},
	},
	data: () => ({
	}),
	created(){

	},
	methods: {

	},
}
</script>
