<template>
	<div>
		<v-toolbar dense flat>
			<v-toolbar-title class="subtitle-2 font-weight-bold"><v-icon left>mdi-cash-clock</v-icon>工资记录</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn v-if="user && user.role === 'admin' " color="info" text small elevation="0" @click="dlgSalaryRecord = true">
					<v-icon left>mdi-plus</v-icon>添加新记录
			</v-btn>
		</v-toolbar>
		<v-divider></v-divider>
		<v-list dense class="mt-0">
			<template  v-for="wage in wages">
				<v-list-item dense :key="wage.id">
					<div class="py-4">
						<div><span class="font-weight-bold body-2 mr-3">$ {{ wage.hourly_rate }} /hr</span><span class="caption">{{ wage.include_gst ? 'Incl. GST':'Excl. GST' }}</span></div>
						<div class="caption">
							开始日期：{{ wage.effective_date }}  | 上次更新时间: {{ wage.update_at }}｜ 由 {{ wage.created_by ? wage.created_by.name : '匿名'  }}在{{ wage.created_at }}添加 | 支付方式: {{ wage.payment_method | getPaymentMethodText}}
						</div>
						<div class="body-2 mt-1" v-text="wage.notes"></div>
					</div>
				</v-list-item>
				<v-divider></v-divider>
			</template>
		</v-list>
		<!-- <v-data-table
			dense
			:headers="headers"
			:items="wages"
			:search="search"
			:mobile-breakpoint="480"
			calculate-widths
		>
		<template v-slot:item.hourly_rate="{item}">
			$ {{ item.hourly_rate }}
		</template>
		<template v-slot:item.include_gst="{item}">
			<v-icon :color="item.include_gst ?  'success' : 'error'">{{ item.include_gst  ? 'mdi-check' : 'mdi-close'}}</v-icon>
		</template>
		</v-data-table> -->
		<v-dialog v-model="dlgSalaryRecord" max-width="480">
			<v-card>
				<v-card-title>添加新的工资记录</v-card-title>
				<v-divider></v-divider>
				<v-card-text class="mt-8">
					<v-text-field
						v-model="newRate"
						label="时薪"
						outlined
						type="number"
						:rules="[rules.required]"
						prefix="$"
						dense
						required
					></v-text-field>
					<v-select
						item-title="text"
						item-value="value"
						:items="paymentMethod"
						v-model="payment_method"
						dense
						outlined
						label="工资支付方式"
					>
					</v-select>
					<v-text-field
						:value="formatDate(startDate)"
						label="开始日期"
						:rules="[rules.required]"
						outlined
						dense
						required
						@click="startDateMenu = true"
						@click:clear="startDate = null"
					></v-text-field>
					<v-textarea
						outlined
						dense
						label="注释"
						:rules="[rules.length]"
						counter="1000"
						v-model="newNote"
					></v-textarea>
					<v-switch
						inset
						class="mt-0"
						v-model="include_gst"
						:label="include_gst ? '时薪含GST' : '时薪不含GST'"
						></v-switch>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="error" @click="dlgSalaryRecord = false">取消</v-btn>
					<v-btn color="success" elevation="0" @click="createUserWage">添加新的时薪</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="startDateMenu" max-width="290">
			<v-date-picker
				v-model="startDate"
				:first-day-of-week="0"
				show-current
				locale="zh-cn"
				class="pb-6"
				@change="startDateMenu = false"
			></v-date-picker>
		</v-dialog>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import UserServices from '../../services/User';
export default {
	name: 'UserPaymentTab',
	props: {
		userId: {
			type: Number,
			required: false,
			default: null
		},
	},
	data: () => ({
		search: '',
		newRate: '',
		startDate: '',
		newNote: '',
		payment_method:'',
		include_gst: false,
		startDateMenu: false,
		paymentMethod: [
			{ text: 'Bank Transfer', value: 'bank_transfer' },
			{ text: 'Cash', value: 'cash'}
		],
		headers: [
          	{ text: '时薪', value: 'hourly_rate', width:120 },
          	{ text: '开始日期', value: 'effective_date', width:160 },
          	{ text: 'Incl.GST?', value: 'include_gst', width:160 , align: 'center' },
          	{ text: '支付方式', value: 'payment_method', width:80 , align: 'center' },
          	{ text: '创建时间', value: 'created_at', width:160  },
          	{ text: '注释', value: 'notes' },
        ],
		dlgSalaryRecord: false,
		wages: [],
		rules: {
			length: v => (v || '').length < 1000 || `备注字数不能超过1000.`,
			required: v => !!v || '这是必填项',
		},
	}),
	created(){
		this.listUserWages();
	},
	computed: {
		...mapState('user', ['user'],),
	},
	filters: {
		getPaymentMethodText(value){
			return value === "bank_transfer" ? "银行转账" : "现金";
		}
	},
	methods: {
		formatDate (date) {
				if (!date) return null
				if (date.includes('-')){
					const [year, month, day] = date.split('-')
				return `${day}/${month}/${year}`
				}
				if (date.includes('/')){
					return date
				}
		},
		async listUserWages(){
			try {
				let response = await UserServices.getUserWageRates(this.userId);

				if(response.data.statusCode === 200){
					this.wages = response.data.data.map((item)=>{
						return {
							id: item.id,
							hourly_rate: item.hourly_rate/100,
							effective_date: item.effective_date,
							notes: item.notes,
							payment_method: item.payment_method,
							include_gst: item.include_gst,
							created_at: item.created_at,
							update_at: item.updated_at,
							created_by: item.created_by
						}
					});
				}
			} catch(error){
				this.$emit('on-error', error)
			}
		},
		async createUserWage(){
			try {
				const body = {
					hourly_rate: this.newRate * 100,
					include_gst: this.include_gst,
					notes: this.newNote,
					payment_method: this.payment_method,
					effective_date: this.formatDate(this.startDate)
				}
				let response = await UserServices.createUserWageRate(this.userId, body);

				if(response.data.statusCode === 200){
					this.listUserWages();
					this.$emit('on-success', response.data.message);
					this.dlgSalaryRecord = false;
				}
			} catch(error){
				this.$emit('on-error', error);
			}
		},
	},
}
</script>
