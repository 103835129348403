<template>
	<div>
		<v-toolbar dense flat>
			<v-toolbar-title class="subtitle-2 font-weight-bold"><v-icon left>mdi-account-group-outline</v-icon>所属团队</v-toolbar-title>
			<v-spacer></v-spacer>
		</v-toolbar>
		<v-divider></v-divider>
		<template v-if="team && team.length">
		<v-list dense>
			<v-list-item v-for="item in team" :key="item.id">
				<template v-if="user.role === 'admin'">
					<v-chip label link outlined color="primary"  :to="{ name: 'viewTeam', params: {id:item.id}}">
								{{ item.name }}
							</v-chip>
					</template>
					<template v-else>

					<v-chip label outlined color="primary" >
						{{ item.name }}
					</v-chip>
					</template>
			</v-list-item>
		</v-list>
		</template>
		<template v-else>
			<div class="caption text-center py-4 grey--text">
				用户不属于任何团队中
			</div>
		</template>
	</div>
</template>
<script>

import { mapState } from 'vuex';
export default {
	name: 'UserTeamTab',
	props: {
		userId: {
			type: Number,
			required: false,
			default: null
		},
		team: {
			type: Array,
			require: true,
		}
	},
	data: () => ({
		headers: [
			{ text: '名称', value: 'name' },
			{ text: '创建者', value: 'owner.name', width: 100 }
		],
	}),
	filters: {
		getTypeLabel(value){
			return value === "team" ? "团队" : "个人";
		}
	},
	computed:{
		...mapState('user', ['user'],),
	},
	watch: {
	},
	created(){

	},
	methods: {

	},
}
</script>
